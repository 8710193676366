import {
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiFillYoutube,
  AiFillGithub,
} from "react-icons/ai";
import ParticlesBackground from "./components/ParticlesBackground";
import Avatar from "./img/Avatar.svg";
import Code from "./img/code.png";
import { BiMailSend } from "react-icons/bi";

function App() {
  return (
    <div>
      <ParticlesBackground />
      <main className="text-white px-10 dark:bg-gray-900 md:px-20 lg:px-40">
        <nav className="flex justify-between items-center pl-5 pr-5 h-28  mb-8">
          <div>
            <h2 className="font-pacifico font-semibold text-3xl ">CK</h2>
          </div>
          <div className="w-72 flex justify-evenly items-center">
            <a
              className="bg-gradient-to-r from-cyan-500 text- to-teal-500 text-white px-4 py-2 border-none rounded-md ml-8"
              href="https://drive.google.com/file/d/172I0QX5qd2-QnocvqrmWjezwsRhjuuJ-/view?usp=share_link"
              targer="_blank"
              download
            >
              Resume
            </a>
          </div>
        </nav>
        <section>
          <div className="text-center p-10 py-5">
            <h2 className="text-5xl py-2 text-cyan-500 font-medium dark:text-teal-400 md:text-6xl">
              Canberk Koç
            </h2>
            <h3 className="text-2xl py-2 md:text-3xl">Developer</h3>
            <p className="text-md py-5 leading-8  max-w-xl mx-auto md:text-xl">
              A highly motivated and detail-oriented Junior Front-End Developer
              with a passion for creating visually appealing and user-friendly
              websites. Strong skills in HTML, CSS, JavaScript, React and Vue.
              Proven ability to work effectively in a team environment and meet
              tight deadlines 😁
            </p>
            <div className="text-5xl flex justify-center gap-16 py-3 text-white dark:text-gray-400">
              <a
                href="https://www.linkedin.com/in/canberk-koc/"
                target="_blank"
              >
                <AiFillLinkedin className="cursor-pointer" />
              </a>
              <a href="https://github.com/canberkkoc1" target="_blank">
                <AiFillGithub className="cursor-pointer" />
              </a>
              <a href="mailto:canberkkoc.dv@gmail.com" target="_blank">
                <BiMailSend className="cursor-pointer" />
              </a>
            </div>
          </div>
        </section>
        <section className="">
          <div className="lg:flex gap-10">
            <div className="text-center p-10 rounded-xl my-10  bg-transparent flex-1">
              <div className="relative py-7 border before:absolute before:-bottom-2 before:-right-2 before:h-4 before:w-4 before:border-b before:border-r before:transition-all before:duration-300 before:ease-in-out after:absolute after:-top-2 after:-left-2 after:h-4 after:w-4 after:border-t after:border-l after:transition-all after:duration-300 after:ease-in-out hover:before:h-[calc(100%+16px)] hover:before:w-[calc(100%+16px)] hover:after:h-[calc(100%+16px)] hover:after:w-[calc(100%+16px)]">
                <img src={Code} width={100} height={100} />
                <h3 className="text-lg font-medium pt-8 pb-2 text-black">
                  Code your dream project
                </h3>
                <p className="py-2 text-2xl">
                  Do you need a frontend developer? You can contact me for your
                  project.
                </p>
                <h4 className="py-4 text-cyan-600 font-extrabold">
                  Technologies I Use
                </h4>
                <p
                  className="bg-gradient-to-r bg-clip-text  text-transparent 
            from-indigo-500 via-teal-300 to-red-600
            animate-text py-1"
                >
                  HTML
                </p>
                <p
                  className="bg-gradient-to-r bg-clip-text  text-transparent 
            from-amber-300 via-teal-300 to-lime-400
            animate-text py-1"
                >
                  CSS
                </p>
                <p
                  className="bg-gradient-to-r bg-clip-text  text-transparent 
            from-blue-500 via-teal-300 to-cyan-500
            animate-text py-1"
                >
                  JavaScript
                </p>
                <p
                  className="bg-gradient-to-r bg-clip-text  text-transparent 
            from-fuchsia-500 via-teal-300 to-cyan-200
            animate-text py-1"
                >
                  ReactJS
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
