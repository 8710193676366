import Particles from "react-tsparticles";
import partConfig from "../config/part-config";
import { useCallback } from "react";
import { loadFull } from "tsparticles";

export default function ParticlesBackground() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  return <Particles params={partConfig} init={particlesInit} />;
}
